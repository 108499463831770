<template>
  <div class="d-flex flex-column">
    <div class="align-self-end" style="margin: 8px">
      <v-btn class="ma-2 blue darken-1" dark @click="exportAsPDF">
        {{ $t("table.exportPDF") }}
      </v-btn>
      <v-btn class="ma-2 blue darken-1" dark @click="exportAsCSV">
        {{ $t("table.exportCSV") }}
      </v-btn>
      <v-btn class="ma-2 blue darken-1" dark @click="exportAsXLSX">
        {{ $t("table.exportXLSX") }}
      </v-btn>
    </div>
    <v-card>
      <v-data-table
        ref="table"
        :headers="headers"
        :items="sumFaixas"
        show-expand
        :expanded.sync="expanded"
        :single-expand="true"
        item-key="virtual_id"
        @update:page="rePaint"
        @update:sort-by="rePaint"
        @update:sort-desc="rePaint"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <td :colspan="startHeaderAt"></td>
              <td
                :colspan="headers.length - 8"
                style="font-weight: bold;font-size: 18px !important;"
              >
                {{ $t("table.header") }}
              </td>
            </tr>
          </thead>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="ml-8" @click="showChartDialog(item)">
            mdi-chart-bar
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding: 6px;">
            <OSViewList
              :faixas="faixas"
              :total="item"
              :osItems="findItemsByOS(item.ordem_servico)"
              :locale="locale"
              @callDialog="showChartDialog"
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-data-table
      ref="printtable"
      style="display: none;"
      :items-per-page="-1"
      :headers="reportHeaders"
      :items="reportData"
    >
    </v-data-table>

    <transition name="wrapper-fade">
      <div
        class="fake-overlay"
        @click.self="showDialog = false"
        v-show="showDialog"
      >
        <div class="dialog">
          <v-btn large class="close-button" icon>
            <v-icon @click.self="showDialog = false">mdi-close</v-icon>
          </v-btn>
          <ChartDialog :faixas="faixas" :dados="chartItem" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import XLSX from "xlsx";
import "jspdf-autotable";
import ChartDialog from "./ChartDialog";
import OSViewList from "./OSViewList";
import hslToRgb from "@/mixins/hslToRgb";
export default {
  mixins: [hslToRgb],
  name: "productivity-table",
  props: ["report", "locale"],
  data() {
    return {
      conversionFactor: 1, // 1 = hectare,  2.471 = acres
      headers: [
        { text: this.$i18n.t("table.serviceOrder"), value: "ordem_servico" },
        {
          text: this.$i18n.t("table.hectareCartography"),
          value: "ha_cartografia"
        },
        {
          text: this.$i18n.t("table.workedArea"),
          value: "vl_area_trabalhada"
        },
        {
          text: this.$i18n.t("table.totalProduction"),
          value: "vl_produtividade"
        },
        {
          text: this.$i18n.t("table.realTCH"),
          value: "vl_tch_estimado"
        }
      ],
      reportHeaders: [
        { text: this.$i18n.t("table.farm"), value: "cd_fazenda" },
        { text: this.$i18n.t("table.zone"), value: "cd_zona" },
        { text: this.$i18n.t("table.field"), value: "desc_talhao" },
        { text: this.$i18n.t("table.farmName"), value: "desc_fazenda" },
        { text: this.$i18n.t("table.serviceOrder"), value: "ordem_servico" },
        {
          text: this.$i18n.t("table.hectareCartography"),
          value: "ha_cartografia"
        },
        {
          text: this.$i18n.t("table.workedArea"),
          value: "vl_area_trabalhada"
        },
        {
          text: this.$i18n.t("table.totalProduction"),
          value: "vl_produtividade"
        },
        {
          text: this.$i18n.t("table.realTCH"),
          value: "vl_tch_estimado"
        }
      ],
      totalFaixas: 0,
      expanded: [],
      faixas: [],
      reportData: this.report,
      sumFaixas: [],
      page: 1,
      startHeaderAt: 5,
      chartItem: null,
      showDialog: false
    };
  },
  computed: {
    serviceOrders() {
      return [...new Set(this.report.map(el => el.ordem_servico))];
    }
  },
  components: {
    ChartDialog,
    OSViewList
  },
  methods: {
    findItemsByOS(os) {
      let osName = os.split("Total ")[1];
      let osItems = this.reportData.filter(r => r.ordem_servico == osName);
      if (osItems.length) return osItems;
      return this.report;
    },
    showChartDialog(item) {
      this.showDialog = true;
      this.chartItem = item;
    },
    calculateTotalsPerServiceOrder() {
      let newLines = [];
      let v_id = new Date().getMilliseconds();
      for (const item of this.serviceOrders) {
        let arr = this.reportData.filter(el => el.ordem_servico == item);
        newLines.push(this.calculateTotals(arr, v_id, item));
        v_id++;
      }
      newLines.push(this.calculateTotals(this.reportData, v_id, ""));
      this.sumFaixas = newLines;
      this.reportData = this.reportData.concat(newLines);
    },
    calculateTotals(array, v_id, os) {
      let newObj = {};

      newObj.cd_fazenda = "-";
      newObj.cd_zona = "-";
      newObj.desc_talhao = "-";
      newObj.desc_fazenda = "-";
      newObj.ordem_servico = this.$i18n.t("table.total") + " " + os;

      let totalArea = array
        .map(el => el.ha_cartografia)
        .reduce((a, b) => a + b, 0);

      newObj.ha_cartografia = totalArea.toFixed(1);

      let totalWorkedArea = array
        .map(el => el.vl_area_trabalhada)
        .reduce((a, b) => a + b, 0);

      newObj.vl_area_trabalhada = totalWorkedArea.toFixed(2);

      let totalProduction = array
        .map(el => el.vl_produtividade)
        .reduce((a, b) => a + b, 0);

      newObj.vl_produtividade = totalProduction.toFixed(2);
      newObj.vl_tch_estimado = (
        totalProduction.toFixed(2) / totalWorkedArea.toFixed(2)
      ).toFixed(2);
      newObj.virtual_id = v_id;
      newObj.rowType = "result";

      let aux = 0;

      for (let index = 0; index < this.totalFaixas; index++) {
        aux = array
          .map(el => el.ha_cartografia * (el["faixa" + index] / 100))
          .reduce((a, b) => a + b, 0);
        newObj["faixa" + index] = ((aux / totalArea) * 100).toFixed(1);
        newObj["faixa_txt" + index] =
          ((aux / totalArea) * 100).toFixed(1) + "%";
      }

      return newObj;
    },
    getClass(rowType) {
      return rowType == "result" ? "bold" : "";
    },
    convertUnit() {
      if (this.locale == "en-us") {
        this.conversionFactor = 2.471;
      } else {
        this.conversionFactor = 1;
      }
      for (const [index, value] of this.reportData.entries()) {
        let ha_cart = value.ha_cartografia * this.conversionFactor;
        let area = value.vl_area_trabalhada * this.conversionFactor;
        let tch = value.vl_tch_estimado / this.conversionFactor;

        this.reportData[index].ha_cartografia = parseFloat(ha_cart.toFixed(2));
        this.reportData[index].vl_area_trabalhada = parseFloat(area.toFixed(2));
        this.reportData[index].vl_tch_estimado = parseFloat(tch.toFixed(2));
      }
      this.updateHeader();
    },
    updateHeader() {
      let h = this.report[0].faixas;

      let i = 0;
      let pos = this.startHeaderAt;
      for (const key in h) {
        // eslint-disable-next-line no-prototype-builtins
        if (h.hasOwnProperty(key)) {
          let values = key.split(" - ");
          let nKey =
            (values[0] / this.conversionFactor).toFixed(2) +
            " - " +
            (values[1] / this.conversionFactor).toFixed(2);

          this.headers[pos] = { text: nKey, value: "faixa_txt" + i };
          this.faixas[i] = nKey;
          i++;
          pos++;
        }
      }
    },
    exportAsCSV() {
      const headers = [this.reportHeaders.map(h => h.text)];

      let csvContent = headers.map(e => e.join(",") + "\n");

      for (const item of this.report) {
        let aux = [
          item.cd_fazenda,
          item.cd_zona,
          item.desc_talhao,
          item.desc_fazenda,
          item.ordem_servico,
          item.ha_cartografia,
          item.vl_area_trabalhada,
          item.vl_produtividade,
          item.vl_tch_estimado
        ];

        for (let index = 0; index < this.totalFaixas; index++) {
          aux.push(item["faixa_txt" + index]);
        }
        aux = aux.join(",") + "\n";
        csvContent += aux;
      }

      let a = document.createElement("a");
      let mimeType = "text/csv;encoding:utf-16";

      a.href = URL.createObjectURL(
        new Blob([csvContent], {
          type: mimeType
        })
      );
      a.setAttribute("download", "export.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    exportAsPDF() {
      let el = this.reportData;
      let rows = [];
      let headers = this.reportHeaders.map(h => h.text);
      let name = "export";

      for (const element of el) {
        let temp = [
          element.cd_fazenda,
          element.cd_zona,
          element.desc_talhao,
          element.desc_fazenda,
          element.ordem_servico,
          element.ha_cartografia,
          element.vl_area_trabalhada,
          element.vl_produtividade,
          element.vl_tch_estimado
        ];

        for (let index = 0; index < this.totalFaixas; index++) {
          temp.push(element["faixa" + index]);
        }

        rows.push(temp);
      }

      var doc = new jsPDF("landscape");
      doc.autoTable(headers, rows, {
        startY: 10,
        styles: { fontSize: 9, overflow: "linebreak" },
        columnStyles: {
          0: { cellWidth: 17 },
          1: { cellWidth: 15 },
          2: { cellWidth: 15 }
        },
        didParseCell: data => {
          // O didParseCell é usado para substituir o conteúdo ou estilos de uma célula específica
          // A função a baixo seleciona as celulas que são parte do body
          // pega apenas as que serão coloridas, ordena e depois faz o calculo para atribuir a cor
          if (data.section === "body") {
            let v = [];
            for (
              let index = 9;
              index < Object.keys(data.row.cells).length;
              index++
            ) {
              v.push(data.row.cells[index]);
            }
            v = v.sort(function(a, b) {
              return b.raw - a.raw;
            });
            let len = v.length;
            let sub = 0.33 / len;
            let i = 0;

            for (let index = 0; index < v.length; index++) {
              let colors = this.hslToRgb(0.33 - sub * i, 0.97, 0.69);
              v[
                index
              ].styles.fillColor = `rgb(${colors[0]},${colors[1]},${colors[2]})`;
              i++;
            }
          }
        }
      });
      doc.save(name + ".pdf");
    },
    exportAsXLSX() {
      let el = this.$refs["printtable"]._vnode.elm.querySelector("table");
      let sheet = XLSX.utils.table_to_sheet(el, { raw: true });
      let page = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(page, sheet, "Report");
      XLSX.writeFile(page, "export.xlsx");
    },
    processarFaixas() {
      for (const [index, item] of this.report.entries()) {
        let f = item.faixas;

        let i = 0;
        for (const key in f) {
          // eslint-disable-next-line no-prototype-builtins
          if (f.hasOwnProperty(key)) {
            const element = f[key];
            this.$set(this.report[index], "faixa" + i, element);
            this.$set(this.report[index], "faixa_txt" + i, element + "%");
            i++;
          }
        }
        this.totalFaixas = i;
      }

      this.createNewHeaders();
    },
    createNewHeaders() {
      let h = this.report[0].faixas;

      let i = 0;
      for (const key in h) {
        // eslint-disable-next-line no-prototype-builtins
        if (h.hasOwnProperty(key)) {
          this.headers.push({ text: key, value: "faixa_txt" + i });
          this.reportHeaders.push({ text: key, value: "faixa_txt" + i });
          this.faixas.push(key);
          i++;
        }
      }
      this.headers.push({
        text: this.$i18n.t("table.showGraph"),
        value: "actions"
      });
      this.headers.push({
        text: this.$i18n.t("table.showDetails"),
        value: "data-table-expand"
      });
    },
    rePaint() {
      setTimeout(this.paint, 100);
    },
    paint() {
      let el = this.$refs["table"]._vnode.elm.querySelector("tbody");
      let trs = el.children;

      for (const tr of trs) {
        let v = [];

        for (
          let index = this.startHeaderAt;
          index < tr.cells.length - 2;
          index++
        ) {
          v.push(tr.cells[index]);
        }
        v = v.sort(function(a, b) {
          return b.innerText.split("%")[0] - a.innerText.split("%")[0];
        });

        let len = v.length;
        let sub = 0.33 / len;
        let i = 0;

        for (let index = 0; index < v.length; index++) {
          let colors = this.hslToRgb(0.33 - sub * i, 0.97, 0.69);
          v[
            index
          ].style.background = `rgb(${colors[0]},${colors[1]},${colors[2]})`;
          i++;
        }
      }
    }
  },
  created() {
    this.processarFaixas();
    this.convertUnit();
    this.calculateTotalsPerServiceOrder();
  },
  mounted() {
    this.paint();
  }
};
</script>

<style>
.result {
  font-weight: bold;
}
.close-button {
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: 200;
  cursor: pointer;
}
.dialog {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 5px;
}
</style>
