<template>
  <div class="chart"></div>
</template>

<script>
import ApexCharts from "apexcharts";
export default {
  name: "productivity-chart",
  props: ["faixa", "dados"],
  data: () => ({
    options: {
      chart: {},
      series: [],
      xaxis: {},
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val + "%";
        },
        offsetY: -10,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + "%";
          }
        }
      }
    },
    chart: null,
    percentages: []
  }),
  methods: {
    drawChart() {
      this.transformData();
      let xAxis = this.faixa;
      this.options.chart.type = "bar";
      let serie = {
        name: "Faixas",
        data: this.percentages
      };
      this.options.series.push(serie);
      this.options.xaxis.categories = xAxis;
      if (!this.chart && this.$el) {
        this.chart = new ApexCharts(this.$el, this.options);
        this.chart.render();
      } else if (this.chart && this.$el) {
        this.chart.updateSeries([
          {
            data: this.percentages
          }
        ]);
      }
    },
    transformData() {
      this.percentages = [];
      for (let index = 0; index < this.faixa.length; index++) {
        this.percentages.push(this.dados["faixa" + index]);
      }
    }
  },
  watch: {
    dados: {
      deep: true,
      handler() {
        this.drawChart();
      }
    }
  },
  mounted() {
    this.drawChart();
  }
};
</script>
<style>
.chart {
  width: 640px;
}
</style>
