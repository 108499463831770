var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"align-self-end",staticStyle:{"margin":"8px"}},[_c('v-btn',{staticClass:"ma-2 blue darken-1",attrs:{"dark":""},on:{"click":_vm.exportAsPDF}},[_vm._v(" "+_vm._s(_vm.$t("table.exportPDF"))+" ")]),_c('v-btn',{staticClass:"ma-2 blue darken-1",attrs:{"dark":""},on:{"click":_vm.exportAsCSV}},[_vm._v(" "+_vm._s(_vm.$t("table.exportCSV"))+" ")]),_c('v-btn',{staticClass:"ma-2 blue darken-1",attrs:{"dark":""},on:{"click":_vm.exportAsXLSX}},[_vm._v(" "+_vm._s(_vm.$t("table.exportXLSX"))+" ")])],1),_c('v-card',[_c('v-data-table',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.sumFaixas,"show-expand":"","expanded":_vm.expanded,"single-expand":true,"item-key":"virtual_id"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.rePaint,"update:sort-by":_vm.rePaint,"update:sort-desc":_vm.rePaint},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('td',{attrs:{"colspan":_vm.startHeaderAt}}),_c('td',{staticStyle:{"font-weight":"bold","font-size":"18px !important"},attrs:{"colspan":headers.length - 8}},[_vm._v(" "+_vm._s(_vm.$t("table.header"))+" ")])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-8",attrs:{"small":""},on:{"click":function($event){return _vm.showChartDialog(item)}}},[_vm._v(" mdi-chart-bar ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"6px"},attrs:{"colspan":headers.length}},[_c('OSViewList',{attrs:{"faixas":_vm.faixas,"total":item,"osItems":_vm.findItemsByOS(item.ordem_servico),"locale":_vm.locale},on:{"callDialog":_vm.showChartDialog}})],1)]}}])})],1),_c('v-data-table',{ref:"printtable",staticStyle:{"display":"none"},attrs:{"items-per-page":-1,"headers":_vm.reportHeaders,"items":_vm.reportData}}),_c('transition',{attrs:{"name":"wrapper-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDialog),expression:"showDialog"}],staticClass:"fake-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.showDialog = false}}},[_c('div',{staticClass:"dialog"},[_c('v-btn',{staticClass:"close-button",attrs:{"large":"","icon":""}},[_c('v-icon',{on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.showDialog = false}}},[_vm._v("mdi-close")])],1),_c('ChartDialog',{attrs:{"faixas":_vm.faixas,"dados":_vm.chartItem}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }