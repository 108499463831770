// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css);"]);
exports.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/apexcharts/3.16.0/apexcharts.min.css);"]);
// Module
exports.push([module.id, "*{font-size:1em!important}.fake-overlay{position:absolute;top:0;bottom:0;right:0;left:0;z-index:15;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:rgba(0,0,0,.4)}#app{font-family:Avenir,Helvetica,Arial,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-align:center;color:#2c3e50;max-width:100vw;width:auto}.wrapper-fade-enter-active,.wrapper-fade-leave-active{-webkit-transition:opacity .3s;transition:opacity .3s}.wrapper-fade-enter,.wrapper-fade-leave-to{opacity:0}", ""]);
// Exports
module.exports = exports;
