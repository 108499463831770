<template>
  <div>
    <v-data-table
      ref="internal_table"
      :headers="headers"
      :items="reportData"
      item-key="virtual_id"
      @update:page="rePaint"
      @update:sort-by="rePaint"
      @update:sort-desc="rePaint"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <td :colspan="startHeaderAt"></td>
            <td
              :colspan="headers.length - 8"
              style="font-weight: bold;font-size: 18px !important;"
            >
              {{ $t("table.header") }}
            </td>
          </tr>
        </thead>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="ml-8" @click="showChartDialog(item)">
          mdi-chart-bar
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import hslToRgb from "@/mixins/hslToRgb";
export default {
  mixins: [hslToRgb],
  name: "OSViewList",
  props: {
    osItems: {
      type: Array,
      default: () => []
    },
    total: {
      type: Object,
      default: () => {}
    },
    locale: {
      type: String,
      default: process.env.VUE_APP_I18N_LOCALE
    },
    faixas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("table.farm"), value: "cd_fazenda" },
        { text: this.$i18n.t("table.zone"), value: "cd_zona" },
        { text: this.$i18n.t("table.field"), value: "desc_talhao" },
        { text: this.$i18n.t("table.farmName"), value: "desc_fazenda" },
        { text: this.$i18n.t("table.serviceOrder"), value: "ordem_servico" },
        {
          text: this.$i18n.t("table.hectareCartography"),
          value: "ha_cartografia"
        },
        {
          text: this.$i18n.t("table.workedArea"),
          value: "vl_area_trabalhada"
        },
        {
          text: this.$i18n.t("table.totalProduction"),
          value: "vl_produtividade"
        },
        {
          text: this.$i18n.t("table.realTCH"),
          value: "vl_tch_estimado"
        }
      ],
      conversionFactor: 1,
      startHeaderAt: 9,
      chartItem: null,
      showDialog: false
    };
  },
  computed: {
    reportData() {
      return this.osItems;
    }
  },
  methods: {
    showChartDialog(item) {
      this.$emit("callDialog", item);
    },
    createNewHeaders() {
      let i = 0;
      for (const key of this.faixas) {
        this.headers.push({ text: key, value: "faixa_txt" + i });
        i++;
      }
      this.headers.push({
        text: this.$i18n.t("table.showGraph"),
        value: "actions"
      });
    },
    paint() {
      let el = this.$refs["internal_table"]._vnode.elm.querySelector("tbody");
      let trs = el.children;
      trs = [].slice.call(trs);
      for (const tr of trs) {
        let v = [];
        let aux = [].slice.call(tr.children);

        for (let index = this.startHeaderAt; index < aux.length; index++) {
          v.push(aux[index]);
        }
        v = v.sort(function(a, b) {
          return b.innerText.split("%")[0] - a.innerText.split("%")[0];
        });

        let len = v.length;
        let sub = 0.33 / len;
        let i = 0;

        for (let index = 0; index < v.length - 1; index++) {
          let colors = this.hslToRgb(0.33 - sub * i, 0.97, 0.69);
          v[
            index
          ].style.background = `rgb(${colors[0]},${colors[1]},${colors[2]})`;
          i++;
        }
      }
    },
    rePaint() {
      setTimeout(this.paint, 100);
    }
  },
  watch: {
    reportData: {
      handler: function() {
        this.rePaint();
      },
      deep: true
    }
  },
  created() {
    this.createNewHeaders();
  },
  mounted() {
    this.paint();
  }
};
</script>

<style></style>
