import Vue from "vue";
import Vuetify, {
  VApp,
  VContent,
  VContainer,
  VRow,
  VCol,
  VTreeview,
  VTreeviewNode,
  VCheckbox,
  VMenu,
  VDivider,
  VBtn,
  VIcon,
  VScrollYTransition,
  VPagination,
  VProgressLinear,
  VTextField,
  VCard,
  VAlert
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VApp,
    VContent,
    VContainer,
    VRow,
    VCol,
    VTreeview,
    VTreeviewNode,
    VCheckbox,
    VMenu,
    VDivider,
    VBtn,
    VIcon,
    VScrollYTransition,
    VPagination,
    VProgressLinear,
    VTextField,
    VCard,
    VAlert
  }
});

export default new Vuetify({});
