<template>
  <v-card max-width="380">
    <div style="margin: 16px">
      <label for="">{{ $t("subtitle.caption") }}: </label>
      <div class="select-wrapper">
        <select class="select" v-model="selectedSubtitle">
          <option
            v-for="item in items"
            v-bind:key="item.cd_id"
            v-bind:value="item.cd_id"
          >
            {{ item.nome }}</option
          >
        </select>
      </div>
    </div>
    <transition name="slide-down">
      <div v-show="selectedSubtitle">
        <wc-date-range
          i18n="any"
          primary="#1e88e5"
          showOkButton="false"
          element-type="normal"
          @DataRange="dateChange"
          :locale="locale"
        >
        </wc-date-range>
      </div>
    </transition>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "filterPane",
  props: ["url", "clientId", "userId", "unityId", "locale"],
  data: () => ({
    items: [],
    selectedSubtitle: "",
    operations: [],
    fechamentos: []
  }),
  methods: {
    async fetchSubtitles() {
      let data = {
        cdCliente: this.clientId,
        usuario: this.userId
      };
      let url = this.url + `/v1/legendas-perfil-produtividade`;
      let aux = [];

      await axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => (aux = response.data))
        .catch(err => {
          throw new Error("There was an error performing the request", err);
        });
      this.items = aux;
    },
    async dateChange(v) {
      let start = v.detail[0].start.match(/(\d{4})-(\d{2})-(\d{2})/);
      start = moment(start[0] + " 00:00:00", "YYYY-MM-DD HH:mm:ss")
        .subtract(3, "hours")
        .unix();
      let end = v.detail[0].end.match(/(\d{4})-(\d{2})-(\d{2})/);
      end = moment(end[0] + " 23:59:59", "YYYY-MM-DD HH:mm:ss")
        .subtract(3, "hours")
        .unix();

      await this.getFechamentos(start, end);
      this.$emit("setFechamentos", this.fechamentos);
      this.$emit("setLegenda", this.selectedSubtitle);
    },
    async getFechamentos(s, e) {
      let url =
        this.url +
        `/v1/list-fechamento-colheita/${this.clientId}/${this.unityId}/${s}/${e}`;
      let aux = [];

      await axios
        .get(url)
        .then(response => (aux = response.data))
        .catch(err => {
          throw new Error("There was an error performing the request", err);
        });
      this.fechamentos = aux;
    }
  },
  mounted() {
    this.fetchSubtitles();
  }
};
</script>

<style>
.select-wrapper {
  display: inline-block;
  position: relative;
  padding: 16px;
}
.select-wrapper::after {
  content: "";
  position: absolute;
  top: 30px;
  right: 25px;
  z-index: 5;
  pointer-events: none;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
}
.select {
  background-color: #1e88e5;
  color: white;
  padding: 0 26px 0 16px;
  height: 36px;
  min-width: 64px;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.select > option {
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s;
  opacity: 1;
  height: 660px;
}
.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  height: 0;
}
</style>
