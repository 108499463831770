<template>
  <v-card>
    <div>
      <v-btn @click="goBack" class="float-left mt-4" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <p class="headline mt-4" style="display: inline-flex !important">
        {{ $t("closings.title") }}
      </p>
    </div>
    <div style="overflow-y: scroll; max-height: 400px; min-width: 500px">
      <v-simple-table v-if="fechamentos">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <v-checkbox v-model="allSelected"></v-checkbox>
              </th>
              <th class="text-left">{{ $t("closings.operation") }}</th>
              <th class="text-left">{{ $t("closings.serviceOrder") }}</th>
              <th class="text-left">{{ $t("closings.startDate") }}</th>
              <th class="text-left">{{ $t("closings.finalDate") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in fechamentos" :key="item.cd_id">
              <td>
                <v-checkbox v-model="selected" :value="item.cd_id"></v-checkbox>
              </td>
              <td>{{ item.desc_operac }}</td>
              <td>{{ item.ordem_servico }}</td>
              <td>{{ item.dt_inicio }}</td>
              <td>{{ item.dt_fim }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-alert class="ma-4" v-else type="warning">
        {{ $t("closings.noData") }}
      </v-alert>
    </div>
    <v-btn
      :disabled="!fechamentos"
      class="ma-2 blue darken-1"
      dark
      @click="emitSelected"
    >
      {{ $t("closings.loadReport") }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "fechamentosPane",
  props: ["fechamentos"],
  data: () => ({
    selected: [],
    allSelected: false
  }),
  methods: {
    emitSelected() {
      this.$emit("selectedFechamentos", this.selected);
    },
    selectAll() {
      if (this.allSelected) {
        for (const key in this.fechamentos) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.fechamentos.hasOwnProperty(key)) {
            const element = this.fechamentos[key];
            this.selected.push(element.cd_id);
          }
        }
      } else {
        this.selected = [];
      }
    },
    goBack() {
      this.$emit("goBack");
    }
  },
  watch: {
    allSelected: function(){
      this.selectAll();
    }
  }
};
</script>

<style></style>
