import { scripts } from "../const/scripts";
const nodes = Array.from(document.querySelectorAll("script")).map(n => n.src);

function injectScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = src;
    script.addEventListener("load", resolve);
    script.addEventListener("error", () => reject("Error loading script."));
    script.addEventListener("abort", () => reject("Script loading aborted."));
    document.head.appendChild(script);
  });
}

function* scriptsIterator() {
  for (let i = 0; i < scripts.length; i++) {
    yield [nodes.indexOf(scripts[i]), scripts[i]];
  }
}

(async () => {
  let promises = [];
  for (let item of scriptsIterator()) {
    if (item[0] === -1) {
      promises.push(injectScript(item[1]));
    }
  }
  await Promise.all(promises);
})();
